import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

const Introduction = ({ description }) => {
  return (
    <div className="grid grid-cols-2-lg place-items-center max-w-1200 mx-auto py-20-lg py-12 px-6 w-90-lg w-full">
      <div className="flex flex-col justify-start items-start w-90-lg me-auto py-12-lg">
        <div className="font-light text-black text-3xl-xxl text-2xl-lg text-xl mb-3 font-medium lh-1-8">
          <BlocksRenderer content={description} />
        </div>
      </div>
      <div className="w-90 h-full relative ms-auto-lg me-auto me-0-lg">
        <div className="h-75 w-75 border-4 border-gray-200 absolute -bottom-10 -right-10" />
        <div className="absolute top-0 left-0 w-full h-full bg-blue-800-40 z-10"></div>
        <StaticImage
          src="../../images/solution/image-1.jpeg"
          quality={100}
          className="object-cover h-full w-full"
          objectFit="cover"
          alt="Solution image"
        />
      </div>
    </div>
  );
};

Introduction.propTypes = {
  description: PropTypes.string,
};

export default Introduction;
