import React from 'react';
import PropTypes from 'prop-types';
import Tabs from './Tabs';
import Details from './Details';

const Products = ({ product, products, currentSolutionSlug }) => {
  return (
    <div className="py-20-xxl py-12 max-w-1200 container px-6 w-90-lg w-full mx-auto">
      <span className="inline-block text-blue-900 mb-6 font-bold text-3xl-xxl text-2xl ">
        Nos products
      </span>
      <Tabs
        currentSolutionSlug={currentSolutionSlug}
        tabs={products}
        currentTab={product?.attributes?.slug}
      />
      <Details types={product?.attributes.product_types?.data} />
    </div>
  );
};

Products.propTypes = {
  product: PropTypes.object,
  products: PropTypes.array,
  currentSolutionSlug: PropTypes.string,
};

export default Products;
