import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const Tabs = ({ currentSolutionSlug, currentTab, tabs }) => {
  return (
    <div className="flex flex-row-lg flex-col items-center w-full mb-12">
      {tabs?.map((tab) => {
        if (tab?.attributes?.slug === currentTab) {
          return (
            <Link
              key={tab?.attributes?.slug}
              className="bg-blue-400 text-white px-6 py-8 flex-1 text-base font-bold text-center w-full-mobile"
              to={`/solutions/${currentSolutionSlug}/${tab?.attributes?.slug}`}
            >
              {tab?.attributes?.name}
            </Link>
          );
        }
        return (
          <Link
            key={tab?.attributes?.slug}
            className="bg-transparent border border-gray-200 text-blue-800 px-6 py-8 flex-1 text-base text-center w-full-mobile"
            to={`/solutions/${currentSolutionSlug}/${tab?.attributes?.slug}`}
          >
            {tab?.attributes?.name}
          </Link>
        );
      })}
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array,
  currentTab: PropTypes.string,
  currentSolutionSlug: PropTypes.string,
};

export default Tabs;
