import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

const Hero = ({ title }) => {
  return (
    <div className="pt-218-px-lg pt-100-px relative pb-12 mb-12-lg mb-0">
      <div className="py-20-xxl py-12 w-50-lg w-full mx-auto flex flex-col flex-row-lg items-center justify-center px-6">
        <div className="mb-4 mb-0-lg">
          <StaticImage
            src="../../../images/solution/icon-1.svg"
            quality={100}
            layout="fixed"
            height={80}
            width={80}
            alt="Icon"
          />
          <div className="absolute top-0 left-0 h-full w-full object-cover -z-10 gradient-blue-62" />
        </div>
        <div className="w-1-px max-h-full h-76-px bg-white mx-12 hidden inline-block-lg" />
        <StaticImage
          src="../../../images/history/history-hero.jpg"
          quality={100}
          layout="constrained"
          className="absolute top-0 left-0 h-full w-full object-cover -z-10"
          alt="History hero"
        />
        <h1 className="font-bold text-5xl-xxl text-4xl-lg text-3xl text-white max-w-1000-xl max-w-75-lg text-center">
          {title}
        </h1>
      </div>
    </div>
  );
};

Hero.propTypes = {
  title: PropTypes.string,
};

export default Hero;
